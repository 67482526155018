
import StoryblokComponent from './storyblok-component.vue';

export default {
  name: 'cms-2-cols-scroll-scrolling-container',
  components: {
    StoryblokComponent,
  },
  props: {
    bloks: {
      type: Array,
      required: true,
    },
  },
};
